import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  View,
  Text,
  StyleSheet,
  DialogFormField,
  ShowHideText,
  FadeView,
  ColorInputField,
} from "../core";
import EditForm from "../components/EditForm";
import LabelView from "../components/LabelView";

export default R14.connect(
  class ProjectPipelineBlockIoEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleIoValueFormat = this.handleIoValueFormat.bind(this);
      this.handleIoRenderer = this.handleIoRenderer.bind(this);
      this.handleAfterSave = this.handleAfterSave.bind(this);
      this.handleRemovePipelineBlockIoItem =
        this.handleRemovePipelineBlockIoItem.bind(this);
      this.state = {
        inputOutputVisible: false,
      };
      this.removedPipelineBlockIoUids = [];
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async handleAfterSave(isSuccess, form, options = {}) {
      if (
        isSuccess &&
        this.props.app.ui.pipeline.exists(this.props.pipelineUid)
      ) {
        let pipeline = await this.props.app.ui.pipeline.instance(
          this.props.pipelineUid
        );
        await pipeline.refresh();
      }
    }
    handleRemovePipelineBlockIoItem(item) {
      if (
        item &&
        item.uid &&
        !this.removedPipelineBlockIoUids.includes(item.uid)
      )
        this.removedPipelineBlockIoUids.push(item.uid);
    }
    handleIoValueFormat(values) {
      return values;
    }
    handleIoRenderer(values) {
      let ioMap = this.props.formData.ioMap;
      let pipelineBlockUid = this.props.formData.values.uid;
      let inputLabel = "";
      let outputLabel = "";
      let inputBlockIo =
        values.inputIoMapKey && ioMap[values.inputIoMapKey]
          ? ioMap[values.inputIoMapKey]
          : null;
      let outputBlockIo =
        values.outputIoMapKey && ioMap[values.outputIoMapKey]
          ? ioMap[values.outputIoMapKey]
          : null;

      if (!inputBlockIo || !outputBlockIo) return null;

      let reverse = false;
      if (inputBlockIo.pipelineBlock.uid === pipelineBlockUid) {
        inputLabel = inputBlockIo.name;
        outputLabel = `${outputBlockIo.pipelineBlock.name}: ${outputBlockIo.name}`;
      } else {
        reverse = true;
        outputLabel = outputBlockIo.name;
        inputLabel = `${inputBlockIo.pipelineBlock.name}: ${inputBlockIo.name}`;
      }

      let labels = [
        <LabelView style={styles.ioItemLabelView} key='input' label='Input'>
          <Text>{inputLabel}</Text>
        </LabelView>,
        <LabelView style={styles.ioItemLabelView} key='output' label='Output'>
          <Text>{outputLabel}</Text>
        </LabelView>,
      ];
      if (reverse) labels.reverse();

      return <View style={styles.ioItemRow}>{labels}</View>;
    }
    get form() {
      return this.props.app.ui.form.pipelineBlockIoForm || {};
    }
    getFormIoSelections(formType, values = null) {
      let formData = this.getIoFormData(formType);
      let ioMap = this.props.formData.ioMap;
      let selections = {
        input: [],
        output: [],
      };
      let currVals = this.form.values;
      let ioKey = formType === "input" ? "input" : "output";
      let relIoKey = formType === "input" ? "output" : "input";
      let relSelections = formData[`${relIoKey}Selections`] || [];
      selections[ioKey] = formData[`${ioKey}Selections`];

      // console.log('start with selections', selections, formData, values && values[`${ioKey}IoMapKey`]);

      // Filter the related selections
      let availRelSelections = [];
      if (values && values[`${ioKey}IoMapKey`]) {
        // A value has been chosen, show available items
        let selectedIo = ioMap[values[`${ioKey}IoMapKey`]];

        let currRelIoVals = [];
        if (currVals[`${ioKey}s`])
          currRelIoVals = currVals[`${ioKey}s`].map((val) => {
            return val[`${relIoKey}IoMapKey`];
          });
        relSelections.forEach((val) => {
          let relIo = ioMap[val.value];
          let currRelIoVal = values[`${relIoKey}IoMapKey`];
          if (
            relIo.dataType !== selectedIo.dataType ||
            relIo.dataStructureType !== selectedIo.dataStructureType
          )
            return;

          // Check if the value is already selected
          if (currRelIoVal !== val.value && currRelIoVals.includes(val.value))
            return;
          availRelSelections.push(val);
        });
        if (!availRelSelections.length)
          availRelSelections.push({
            label: `No available ${relIoKey}s.`,
            value: null,
          });
      } else
        availRelSelections.push({
          label: `Please select an ${ioKey}.`,
          value: null,
        });
      selections[relIoKey] = availRelSelections;
      return selections;
    }
    handleIoFormValueChange(form, formType, val, initialValues = null) {
      let ioKey = formType === "input" ? "input" : "output";
      let relIoKey = formType === "input" ? "output" : "input";
      let selections = this.getFormIoSelections(formType, form.values);
      form.elmts[`${relIoKey}IoMapKey`].items = selections[relIoKey];
    }
    getIoFormData(formType) {
      return this.formData[`${formType}s`];
    }
    ioFormRenderer(formType, initialValues = null) {
      let formData = this.getIoFormData(formType);
      let onValueChange = (val, elmt, form) =>
        this.handleIoFormValueChange(form, formType, val, initialValues);
      let selections = this.getFormIoSelections(formType, initialValues);
      let fields = [
        <View key='inputs'>
          <SelectMenuField
            useValuesOnly
            key='input'
            name='inputIoMapKey'
            label='Input'
            required='Select input'
            onValueChange={formType === "input" ? onValueChange : null}
            items={selections.input}
          />
          {formType === "input" && (
            <ColorInputField
              name='inputBackgroundColor'
              key='inputBackgroundColor'
              label='Input Color'
              // required='Please enter a background color.'
              validator='hexColor'
              helper='Optional, Dark color suggested.'
            />
          )}
        </View>,
        <View key='outputs'>
          <SelectMenuField
            useValuesOnly
            key='output'
            name='outputIoMapKey'
            label='Output'
            required='Select input'
            onValueChange={formType === "output" ? onValueChange : null}
            items={selections.output}
          />
          {formType === "output" && (
            <ColorInputField
              name='outputBackgroundColor'
              key='outputBackgroundColor'
              label='Output Color'
              // required='Please enter a background color.'
              validator='hexColor'
              helper='Optional, Dark color suggested.'
            />
          )}
        </View>,
      ];
      if (initialValues && initialValues.uid)
        fields.push(<HiddenInputField name='uid' key='' />);
      formType === "output" && fields.reverse();
      return fields;
    }

    render() {
      return (
        <EditForm
          name='pipelineBlockIoForm'
          updateMethod='updateIo'
          createMethod='updateIo'
          formData={this.props.formData}
          domainName='pipelineBlock'
          redirect={this.props.redirect || null}
          saveOptions={{
            removedPipelineBlockIoUids: this.removedPipelineBlockIoUids,
            ioMap: this.props.formData.ioMap,
          }}
          onAfterSave={this.handleAfterSave}
          validators={{
            uniqueKey: (val) => {
              return true;
              let keys = {};
              let isValid = true;

              if (val) {
                for (let i in val) {
                  let keyNames = ["inputIoMapKey", "outputIoMapKey"];
                  for (let key of keyNames) {
                    let keyVal = val[i][key];
                    if (keys[keyVal]) {
                      isValid = false;
                    }
                    keys[keyVal] = true;
                  }
                }
              }
              return isValid ? true : { error: "All keys must be unique." };
            },
          }}
        >
          <HiddenInputField name='uid' />
          <HiddenInputField name='pipelineUid' />
          <DialogFormField
            name='inputs'
            dialogTitle='Add Input'
            label='Inputs'
            formName='pipelineBlockIoForm'
            itemRenderer={this.handleIoRenderer}
            onRemoveItem={this.handleRemovePipelineBlockIoItem}
            validator='uniqueKey'
            // onValueChange={(val) => {
            //   console.log("INPUT VALUE CHANGE", val);
            // }}
            dialogFormRenderer={(initialValues) =>
              this.ioFormRenderer("input", initialValues)
            }
          />
          <DialogFormField
            name='outputs'
            dialogTitle='Add Output'
            label='Outputs'
            formName='pipelineBlockIoForm'
            validator='uniqueKey'
            itemRenderer={this.handleIoRenderer}
            onRemoveItem={this.handleRemovePipelineBlockIoItem}
            dialogFormRenderer={(initialValues) =>
              this.ioFormRenderer("output", initialValues)
            }
          />
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  ioItemRow: {
    flex: 1,
    flexDirection: "row",
  },
  ioItemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 12,
  },
  ioItemLabelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
});
